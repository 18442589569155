<template>
  <div class="mb-4 mx-auto">
    <div class="customer-detail">
      <SectionTitle sectionTitle="PRICING"></SectionTitle>
      <div class="fs-20 font-weight-bold car-title text-center mb-21">
        {{ regoInfo.rego ? `${regoInfo.rego} | ` : '' }}
        {{ info.year }} {{ info.make.toUpperCase() }}
        {{ info.model.toUpperCase() }}
        {{ info.badge.toUpperCase() }}
        {{ info.edition.toUpperCase() }}
      </div>
      <div
        class="
          customer-detail-content
          justify-content-between
          align-items-center
          text-center
          mx-auto
        "
      >
        <div class="flex-content-detail">
          <div class="left-content-detail font-weight-bold">
            <div class="content-detail">
              <p class="mb-10 font-weight-regular fs-24">Estimated price range</p>
              <div
                class="
                  autoflip-price
                  d-flex
                  justify-content-center
                  align-items-center
                  font-weight-bold
                "
                :class="hasGotPrice ? '' : 'no-price'"
              >
                <span v-if="hasGotPrice" class="fs-20">
                  {{ `${fromPrice} - ${toPrice}` }}*
                </span>
                <span v-else class="no-valuation">
                  We can't provide an accurate price guidance right now
                </span>
              </div>
              <p v-if="hasGotPrice" class="car-note font-weight-regular mb-0">
                Please note this is a guide only based on market data. The actual sale price could be higher or lower and depends on various factors.<br/><br/>
                After you submit, our experienced team can provide a more accurate estimate that considers real-time market demand for your vehicle, and its unique details and condition.
              </p>
              <p v-else class="car-note font-weight-regular mb-0">
                Unfortunately, we don't have enough information to provide a price guide based on market data.<br/><br/>
                After you submit, our experienced team will contact you and provide an informed estimate of your vehicle's value that considers the real-time market demand for your vehicle, and its unique details and condition.
              </p>
              <!-- DMV-194 -->
            </div>
          </div>
          <div class="right-content-detail mt-5">
            <div class="content-detail">
              <div class="position-relative">
                <float-label label="Reserve price" class="mb-0">
                  <b-input
                    v-model.trim="info.reservePrice"
                    ref="reservePriceInput"
                    placeholder="Enter your reserve price"
                    autocomplete="off"
                    required
                    type="number"
                    pattern="\d*"
                    @keypress="isNumber"
                    :class="[
                      {
                        error: $v.info.reservePrice.$error,
                      },
                      'shadow-none',
                    ]"
                    v-on:focusout="handleShowExplanation(false)"
                    @click="handleShowExplanation(true)"
                    @keyup="removeFirstZero"
                    @blur="setNextInputNumber"
                  />
                  <p
                    v-if="
                      $v.info.reservePrice.$dirty &&
                      !$v.info.reservePrice.required
                    "
                    class="msg-error mb-0"
                  >
                    <i>Please enter your reserve price</i>
                  </p>
                  <p
                    v-else-if="
                      $v.info.reservePrice.$dirty &&
                      !$v.info.reservePrice.numeric
                    "
                    class="msg-error mb-0"
                  >
                    <i>Please enter only numerics!</i>
                  </p>
                  <p
                    v-else-if="
                      $v.info.reservePrice.$dirty &&
                      !$v.info.reservePrice.maxLength
                    "
                    class="msg-error mb-0"
                  >
                    <i>Please enter 1 to 20 number!</i>
                  </p>
                </float-label>
              </div>
              <p
                class="price-desc fs-8 text-right mb-2"
                :class="[
                  !$v.info.reservePrice.$error ? 'mt-n2' : 'mt-1',
                ]"
              >
                <!-- <i>Tell us the minimum amount you would be happy with!</i> -->
              </p>
              <div class="explanation" 
                :class="reservePriceToolTip.showFadeOut ? 'fadeOutAnimation' : ''" 
                v-show="reservePriceToolTip.showExplanation">
                {{ floatLabelTooltip.content }}
              </div>
              <custom-select
                label="When are you looking to sell?"
                v-model="info.sell"
                :options="sells"
                :error="$v.info.sell.$error"
                helperText="Please select one option"
                class="mt-30"
                @blur="setNextInputNumber"
              />
              <custom-select
                v-if="selling" 
                label="When is your car available?"
                v-model="info.pickUp"
                :options="pickUps"
                :error="$v.info.pickUp.$error"
                helperText="Please select pick-up time"
                @blur="setNextInputNumber"
              />
            </div>
          </div>
        </div>
        <btn-group
          class="pb-5 pt-4 mt-4 group-action"
          @onOk="next"
          @onCancel="() => {}"
          back="condition-info"
          :okBtnDisable="activeBtnAction"
          :query=this.$route.query
        />
      </div>
    </div>
    <WhySell :items="whySellItems" />
    <HowItWorks :items="howItWorksItems" />
    <p class="customer-detail-txt-note font-weight-thin mt-2 mb-0">
      {{ valuationDisclaimer }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  required,
  maxLength,
  numeric,
  requiredIf,
} from "vuelidate/lib/validators";
import mixin from "@/mixins/common";
import { SET_INFO } from "@/store/info/actions";
import customerDetailService from "@/services/customer-detail";
import { LASTEST_DEAL } from "@/config/constants";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import { removeFormatNumberWithComma } from "../../helper/utils";
import qualifierService from "@/services/self-service-qualifier";
import { ADD_LOG } from "@/store/self-service-qualifier/actions";
import { blankOptionList } from "@/helper/constant";

export default {
  name: "CustomerDetail",
  mixins: [mixin],
  data() {
    return {
      reservePriceToolTip: {
        showExplanation: false,
        showFadeOut: false,
      },
      selling: false,
      // privacy_policy: null,
      seller_terms: null,
      carSale: LASTEST_DEAL,
      from: 0,
      to: 0,
      settings: {
        dots: false,
        arrows: false,
        focusOnSelect: true,
        swipeToSlide: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
      },
      curStepInput: 1,
      inputStep: [
        {
          step: 1,
          key: "reservePrice",
        },
        {
          step: 2,
          key: "sell",
        },
        {
          step: 3,
          key: "pickUp",
        },
        {
          step: 4,
          key: "",
        },
      ],
      floatLabelTooltip: {
        content:
          "This is the lowest price you'll sell for. Don't worry, our large dealer network will competitively bid, ensuring you get the best price.",
      },
      regoInfo: this.$store.getters.regoSearch,
      howItWorksItems: [
        {
          title: "Discover your car's estimated price range",
          subTitle: 'Receive a price guide and enter a reserve price',
          path: 'Contacts',
        },
        {
          title: 'Receive your best offer',
          subTitle: 'Get a competitive trade-in offer for your car from our 500+ buying network, ready to buy now',
          path: 'Car',
        },
        {
          title: 'Get paid fast',
          subTitle: `Pay the <a href="https://www.autoflip.com.au/seller-fees" target="_blank">success fee</a>, connect with your successful buyer and get paid quickly`,
          path: 'News',
        },
      ],
      whySellItems: [
        {
          title: 'Best price',
          path: 'sell',
        },
        {
          title: 'Free to list',
          path: 'free-to-list',
        },
        {
          title: 'Safe & secure',
          path: 'safe',
        },
      ]
    };
  },
  methods: {
    next: async function() {
      this.$store.dispatch("setProgress", 96);
      this.$v.info.$touch();
      if (this.activeBtnAction) {
        this.$store.dispatch(SET_INFO, {
          ...this.info,
        });
        await this.selfServiceTrack();
        this.$router.push({ name: "contact", query: this.$route.query });
      }
    },
    handleShowExplanation(isShow) {
      if (isShow === false) {
        this.reservePriceToolTip.showFadeOut = true;
        setTimeout(() => {
          this.reservePriceToolTip = {
            showExplanation: isShow,
            showFadeOut: false
          };
        }, 220);
      } else {
        this.reservePriceToolTip.showExplanation = isShow;
      }
    },
    removeFirstZero() {
      if (/^0/.test(this.info.reservePrice)) {
        this.info.reservePrice =
          this.info.reservePrice.replace(/^0+/, "");
      }
    },
    getPriceRange() {
      customerDetailService
        .getPriceRange({
          make: this.info.make,
          model: this.info.model !== 'Other' ? this.info.model : '',
          year: this.info.year,
          badge: !blankOptionList.includes(this.info.badge) ? this.info.badge : '',
          edition: !blankOptionList.includes(this.info.edition) ? this.info.edition : '',
          bodyType: this.info.bodyType,
          fuelType: this.info.fuelType,
          drive: this.info.drive,
          transmission: this.info.transmission,
          series: !blankOptionList.includes(this.info.series) ? this.info.series : '',
          engineType: this.info.engineType !== 'Not sure' ? this.info.engineType : '',
          valuationType: 'Range',
          km: removeFormatNumberWithComma(this.info.kms),
          exteriorCondition: this.info.exterior,
          vin: this.info.vin,
          modelYear: this.info.model_year,
          rbcs: encodeURIComponent(JSON.stringify(this.info.rbcs))
        })
        .then(({ data }) => {
          this.from = data.from || 0;
          this.to = data.to || 0;
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      let charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode >= 48 &&
        charCode <= 57 &&
        !(charCode === 48 && this.info.kms === "")
      ) {
        return true;
      } else {
        evt.preventDefault();
        return false;
      }
    },
    async selfServiceTrack() {
      const { reason } = await qualifierService.track(
        5,
        { 
          valuation: this.from > 0 && this.to > 0 ? `${this.from}-${this.to}` : '',
          reservePrice: this.info.reservePrice,
          location: this.info.location,
          rego: this.info.rego,
          sell: this.info.sell,
          pickUp: this.info.pickUp,
        },
        this.selfServiceQualifier.config
      )
      this.$store.dispatch(ADD_LOG, { step5: reason.step5 });
    },
    handleCheckingActive() {
      if (this.info.sell === ''){
        return false
      } else if (this.info.sell !== "Now") {
        if (!this.$v.info.reservePrice.$error &&
        !this.$v.info.sell.$error &&
        this.info.reservePrice) {
          return true
        } else {
          return false
        }
      } else {
        if (!this.$v.info.reservePrice.$error &&
        !this.$v.info.sell.$error &&
        !this.$v.info.pickUp.$error && 
        this.info.reservePrice && 
        this.info.pickUp) {
          return true
        } else {
          return false
        }
      }
    },
    setNextInputNumber() {
      for (const item of this.inputStep) {
        if (!this.info[item.key] || item.key === "") {
          this.curStepInput = item.step;
          break;
        }
      }
    },
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      info: (state) => state.info.curVehicleInfo,
      selfServiceQualifier: (state) => state.selfServiceQualifier,
      valuationDisclaimer: (state) => state.commonVariables.copyBlocks.valuationDisclaimer,
    }),
    activeBtnAction: function () {
      return this.handleCheckingActive();
    },
    fromPrice() {
      return "$" + new Intl.NumberFormat().format(this.from);
    },
    toPrice() {
      return "$" + new Intl.NumberFormat().format(this.to);
    },
    sells: function() {
      return ["Now", "Later",  "I'm not selling"];
    },
    pickUps: function() {
      return ["Within a week after sale", "More than a week after sale"];
    },
    hasGotPrice: function() {
      return this.from > 0 && this.to > 0
    },
    isQualified() {
      for (const key of Object.keys(this.selfServiceQualifier.logs)) {
        if (this.selfServiceQualifier.logs[key].length > 0) {
          return false;
        }
      }
      return true;
    },
  },
  validations: {
    info: {
      reservePrice: {
        required,
        numeric,
        maxLength: maxLength(20),
      },
      sell: {
        required,
      },
      pickUp: {
        required: requiredIf(function(){
          return this.info.pickUp === '' && this.info.sell === "Now"
        })
      },
    },
  },
  watch: {
    "info.reservePrice": function(val) {
      if (val) {
        this.curStepInput = 1
      }
    },
    "info.sell": function(val) {
      if (val) {
        this.curStepInput = 2
      }
      if (val === "Now") {
        this.selling = true;
      } else {
        this.selling = false;
      }
    },
    "info.pickUp": function(val) {
      if (val) {
        this.curStepInput = 3
      }
    },
    curStepInput: function () {
      let progress = 0;
      for (const item of this.inputStep) {
        if (this.info[item.key] && item.key !== "") {
          progress = progress + (10 / (this.inputStep.length - 1));
        }
      }
      // this.$store.dispatch("setProgress", Math.floor(progress) * 10);
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.reservePriceInput) {
        this.$nextTick(() => this.$refs.reservePriceInput.focus());
      }
    }, 1000);
    this.$store.dispatch(SET_INFO, {
      ...this.info,
      kms: this.info.kms,
      keys:
        this.info.key === "3+"
          ? 3
          : parseInt(this.info.key),
      interiorCondition: this.info.interior,
      exteriorCondition: this.info.exterior,
      images: [],
    });
    if (!this.$store.getters.sendPhotoLater) {
      this.$store.dispatch(SET_INFO, {
        ...this.info,
        images: this.$store.getters.uploadImage,
      })
    }
    if (this.info.sell === "Now") {
      this.selling = true
    }
  },
  components: {
    FloatLabel: () => import("../../components/common/FloatLabel.vue"),
    CustomSelect: () => import("../../components/common/CustomSelect"),
    SectionTitle: () => import("../../components/vehicle/SectionTitle.vue"),
    BtnGroup: () => import("../../components/common/BtnGroup.vue"),
    HowItWorks: () => import("./HowItWorks.vue"),
    WhySell: () => import("../contact/WhySell.vue"),
  },
  created() {
    this.getPriceRange();
  },
};
</script>

<style lang="scss">
.msg-error {
  font-size: 12px;
  color: #c1272d;
  text-align: right;
  float: right;
  margin-top: 2px;
}
.customer-detail {
  padding: 0 20px 0px;
  background-color: #ffffff;

  .customer-detail-content {
    .title {
      line-height: 20px;
    }

    .left-content-detail .content-detail {
      padding: 0 5px;
    }

    .right-content-detail .content-detail {
      padding: 37px 8px 20px;
    }

    .left-content-detail,
    .right-content-detail {
      // width: calc(100% / 2 - 15px);

      .content-detail {
        border-radius: 20px;
        padding-bottom: 20px;
        .price-desc {
          color: #999999;
        }

        .explanation {
          font-family: Avenir Next LT W05 Regular;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.025em;
          color: #999999;
          text-align: left;
          margin-left: 0.8rem;
          animation: fadeIn 0.5s ease both;
        }

        .fadeOutAnimation {
          animation: fadeOut 0.2s ease both;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translate3d(0, -20%, 0);
          }
          to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
        }

        @keyframes fadeOut {
          from {
            opacity: 1;
            transform: translate3d(0, 0, 0);
          }
          to {
            opacity: 0;
            transform: translate3d(0, -20%, 0);
          }
        }

        .autoflip-price {
          color: #141414;
          font-family: Avenir Next LT W05 Demi;
          max-width: 303px;
          height: 71px;
          margin: 6px auto 40px;
          background-color: var(--primary-color);
          border-radius: 30px;
          width: 80%;

          .no-valuation {
            font-size: 16px;
            padding: 10px;
          }
        }

        .latest-autoflip-content {
          .autoflip-slide {
            max-width: 434px;

            .slick-slide {
              display: flex;
              justify-content: center;
              align-items: center;

              div {
                max-width: 138px;
              }

              .item {
                width: fit-content;
                background-color: #ffffff;
                border-radius: 10px;

                .img {
                  width: 26vw;
                  height: calc(26vw * 0.6);
                  background-color: #dadada;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                }

                .autoflip-info {
                  padding: 5px 8px;

                  .price-item {
                    color: #00b86d;
                    font-size: 12px;
                    margin-top: 6px;
                  }
                }
              }
            }
          }
        }

        .error, select.form-control.invalid, input.form-control.invalid {
          border-color: #c1272d !important;
        }

        .msg-error {
          font-size: 12px;
          color: #c1272d;
          position: absolute;
          right: 0;
          text-align: right;
          margin-top: 2px;
        }

        .err-text {
        color: #c1272d;
        padding-right: unset !important;
      }

        input {
          font-size: 16px;
          color: #383C3F;
          padding-left: 20px;
          font-weight: bold;
          border-color: #b3b3b3;
          border-radius: 10px;

          &:focus {
            box-shadow: none;
            border-color: var(--primary-color);
            outline: 0;
          }

          &:disabled {
            background-color: #ffffff;
            border-color: #b3b3b3;
          }
        }

        span {
          &:after {
            // background: #f1f1f1;
          }
        }

        .border-lable-flt span {
          // background-color: unset;
        }
      }
    }
  }

  .action {
    margin: 15px 0 0px;
    padding: 0 8px 0;

    .btn-action {
      width: 100%;
      height: 52px;
      padding: 16px 24px;
      margin-top: 12px;
      background-color: #C4C4C4;
      border-radius: 0.5rem;
      font-size: 16px;
      text-transform: uppercase;
      cursor: pointer;
    }

    .active-btn-action {
      background-color: #2BB673;

      &:hover, &:active {
        background: linear-gradient(0deg, rgba(98, 0, 238, 0.08), rgba(98, 0, 238, 0.08)), #2BB673 !important;
      }
    }

    .btn-loading {
      font-size: 16px;
      width: 24px;
      height: 24px;
    }

    .btn-back {
      background-color: #ffffff !important;
      border: 1px solid #2BB673;
      color: #2BB673;
    }

    .btn-submit {
      &:hover, &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #C4C4C4;
        outline: none;
      }
    }
  }

  .seller-customer {
    color: black;
    margin-top: 10px;
    text-align: left;

    .seller-checkbox {
      label::before,
      label::after {
        width: 20px;
        height: 20px;
      }

      label::before {
        // border-radius: 0px;
      }
    }

    input:checked ~ label::before {
      color: #74bd58;
      background-color: #ffffff;
      border-color: #74bd58;
    }

    input:checked ~ label::after {
      background-image: url("~@/assets/images/icons/ic_done.svg") !important;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .txt-checked {
      color: #74bd58;

      a {
        color: #74bd58;
      }
    }

    span {
      margin: 3px 0 0 3px;
    }

    a {
      color: black;
      text-decoration: underline;
    }
  }
}

.customer-detail-txt-note {
  font-size: 11px;
  color: #989898;
  text-align: center;
  max-width: 1080px;
  padding: 0 10px;
}
.no-price {
  height: 80px !important;
}

.term-note {
  text-align: left;
}

.car-note {
  text-align: left;
  font-size: 16px;
  padding-left: 10px;
}

.card {
  border: none !important;
}

@media screen and (min-width: 768px) {
  .customer-detail {
    padding: 0 0 50px !important;
    .divider-wrapper {
      display: flex !important;
    }
    .customer-detail-content {
      .flex-content-detail {
        display: flex;
        justify-content: space-between;
        .left-content-detail,
        .right-content-detail {
          width: calc(50% - 1px);
          height: 400px;
        }
        .left-content-detail {
          .content-detail {
            padding: 37px 8px 20px !important;
            .autoflip-price {
              max-width: 340px !important;
              .no-valuation {
                font-size: 18px !important;
                padding: 10px 30px !important;
              }
            }
            .car-note {
              padding-left: 50px !important;
            }
          }
        }
        .right-content-detail {
          margin-top: 0 !important;
          .content-detail {
            padding: 40px !important;
          }
        }
      }
    }
    .action {
      flex-direction: row !important;
      justify-content: space-between;
      .btn-action {
        width: unset !important;
      }
    }
  }
  .customer-detail-txt-note {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1200px) {
  .customer-detail {
    padding: 0 50px 50px !important;
  }
}

@media screen and (max-width: 430px) {
  .explanation {
    margin-left: 0 !important;
  }

  .car-title {
    margin-bottom: 0 !important;
  }

  .content-detail {
    padding: 20px 5px !important;
  }

  .right-content-detail {
    margin-top: 1rem !important;
  }

  .group-action {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
}
</style>
